import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/99289321ff23935f513d7e574fcfe1fd0756a17e12dc9b40c9477155f97834d5/invoices-nav.module';
import { InvoiceButtonsModule } from './extensions/ba200e114f9714ee6c1a826e6d984838f6d2e636b7d060b9ed74df179850e9fb/invoice-buttons.module';

import SharedProviders_2_0 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/mark-shipped-button.module';
import SharedProviders_2_1 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/label-button.module';
import SharedProviders_2_2 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/manifest-button.module';
import SharedProviders_2_3 from './extensions/21d4404d5960a48be673c07ace9e1648ef3c6dd5984cda2a3aa4bce558fc7778/customs-button.module';
import SharedProviders_3_0 from './extensions/fa7c6b8d69f54965913c2e5eb76c937dcc88b29347a87dda94b05772245039f1/providers';
import SharedProviders_4_0 from './extensions/5b355a46194ddcb51bf1b3d934529adef519d9ea6dc8cc16719f77866a0aa71f/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule, InvoiceButtonsModule],
    providers: [...SharedProviders_2_0, ...SharedProviders_2_1, ...SharedProviders_2_2, ...SharedProviders_2_3, ...SharedProviders_3_0, ...SharedProviders_4_0],
})
export class SharedExtensionsModule {}
