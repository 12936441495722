export const extensionRoutes = [  {
    path: 'extensions/invoices',
    loadChildren: () => import('./extensions/99289321ff23935f513d7e574fcfe1fd0756a17e12dc9b40c9477155f97834d5/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: 'extensions/orders-extended',
    loadChildren: () => import('./extensions/fa7c6b8d69f54965913c2e5eb76c937dcc88b29347a87dda94b05772245039f1/routes'),
  },
  {
    path: 'extensions/tax-export',
    loadChildren: () => import('./extensions/5b355a46194ddcb51bf1b3d934529adef519d9ea6dc8cc16719f77866a0aa71f/routes'),
  }];
